<template>
    <div class="auth-wrap">
        <transition name="fade" mode="out-in">
            <div v-if="!success && !failed">
                <div class="auth-title">{{ $t('auth.resetpw_welcome') }}</div>
                <password-reset-form
                    :token="token"
                    @passwordResetSuccess="success = true"
                    @passwordResetFailed="failed = true"
                ></password-reset-form>
            </div>

            <div v-if="success && !failed">
                <div class="auth-title success--text">
                    {{ $t('auth.resetpw_success') }}
                </div>
                <div class="py-4">
                    <v-btn
                        :to="{ name: routeType.LOGIN }"
                        color="primary"
                        class="white--text"
                        block
                    >
                        {{ $t('auth.back_to_login') }}
                    </v-btn>
                </div>
            </div>

            <div v-if="failed">
                <div class="auth-title error--text">
                    {{ $t('auth.resetpw_invalid_token') }}
                </div>
                <div class="py-4">
                    <v-btn
                        :to="{ name: routeType.PASSWORD_RESET_REQUEST }"
                        color="primary"
                        class="white--text"
                        block
                    >
                        {{ $t('auth.password_reset') }}
                    </v-btn>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PasswordResetForm from '@/components/pages/login/PasswordResetForm'

export default {
    components: { PasswordResetForm },
    data() {
        return {
            success: false,
            failed: false,
            token: '',
            routeType,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler: function (route) {
                this.token = route.params.token
            },
        },
    },
}
</script>
