<template>
    <div class="auth-wrap">
        <transition name="fade" mode="out-in">
            <div v-if="!success">
                <div class="auth-title">{{ $t('auth.reset_welcome') }}</div>
                <p class="text-center">
                    <small>{{ $t('auth.reset_small') }}</small>
                </p>
                <password-reset-request-form
                    @passwordResetSuccess="success = true"
                ></password-reset-request-form>

                <p class="text-center">
                    <router-link :to="{ name: routeType.LOGIN }">
                        {{ $t('auth.back_to_login') }}
                    </router-link>
                </p>
            </div>

            <div v-else>
                <div class="auth-title success--text">
                    {{ $t('auth.reset_success') }}
                </div>
                <div class="py-4">
                    <v-btn
                        :to="{ name: routeType.LOGIN }"
                        color="primary"
                        class="white--text"
                        block
                    >
                        {{ $t('auth.back_to_login') }}
                    </v-btn>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PasswordResetRequestForm from '@/components/pages/login/PasswordResetRequestForm'

export default {
    components: { PasswordResetRequestForm },
    data() {
        return {
            success: false,
            routeType,
        }
    },
}
</script>

<style>
/* style in pages/_auth */
</style>
