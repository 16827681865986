<template>
    <v-form @submit.prevent="passwordResetRequest">
        <v-text-field
            v-model="email"
            v-validate="'required|email'"
            class="email"
            name="email"
            :label="$t('auth.email')"
            type="email"
            required
            :disabled="disabled"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
            data-vv-validate-on="blur"
            autofocus
        ></v-text-field>

        <div class="py-4">
            <v-btn
                type="submit"
                class="white--text"
                color="primary"
                block
                :disabled="disabled"
            >
                {{
                    $t(
                        !disabled
                            ? 'auth.reset_req_submit'
                            : 'auth.reset_req_submitting'
                    )
                }}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import AuthApiClient from '@/api/AuthApiClient'
import configType from '@/store/type/configType'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            email: '',
            disabled: false,
        }
    },
    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
    },
    methods: {
        passwordResetRequest() {
            this.$validator.validateAll().then((isValid) => {
                if (!isValid) return

                AuthApiClient.passwordResetRequest(
                    this.email,
                    this.currentLocale
                )
                    .then(() => {
                        this.$emit('passwordResetSuccess')
                    })
                    .finally(() => {
                        this.disabled = false
                        this.email = ''
                    })

                this.disabled = true
            })
        },
    },
}
</script>
