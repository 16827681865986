<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'

export default {
    data: () => ({
        routeType,
    }),
    computed: {
        ...mapGetters({
            isLoggedIn: authType.getters.IS_LOGGED_IN,
        }),
    },

    watch: {
        isLoggedIn: {
            immediate: true,
            handler(v) {
                if (v) this.$router.replace({ name: routeType.ORDER_LIST })
            },
        },
    },

    async mounted() {
        await this.login().catch(() =>
            this.$router.replace({ name: routeType.LOGIN })
        )
    },

    methods: {
        login() {
            return new Promise((resolve, reject) => {
                if (!this.$route.query.token) reject()
                this.$store
                    .dispatch(
                        authType.actions.LOGIN_WITH_REFRESH_TOKEN,
                        this.$route.query.token
                    )
                    .then(() => resolve())
                    .catch(() => reject())
            })
        },
    },
}
</script>

<template lang="pug">
.auth-wrap.text-center(v-if="!isLoggedIn")
    transition(name="fade" mode="out-in")
        v-progress-circular(color="primary" indeterminate)
</template>
