<template>
    <div v-if="!isLoggedIn" class="auth-wrap">
        <transition name="fade" mode="out-in">
            <div v-if="!isInlined">
                <div class="auth-title">
                    {{ $t('auth.welcome') }}
                </div>
                <login-form></login-form>
                <p class="text-center">
                    <router-link
                        :to="{ name: routeType.PASSWORD_RESET_REQUEST }"
                    >
                        {{ $t('auth.password_reset') }}
                    </router-link>
                </p>
            </div>
        </transition>

        <transition name="fade" mode="out-in">
            <div v-if="isInlined && inlinedAuthError">
                <v-card class="mx-auto" color="error" dark max-width="400">
                    <v-card-title>
                        <span class="title font-weight-light">Error</span>
                    </v-card-title>

                    <v-card-text class="headline font-weight-bold">
                        {{ $t('auth.inline_failed') }}
                    </v-card-text>
                </v-card>
            </div>
        </transition>

        <transition name="fade" mode="in-out">
            <div v-if="isInlined && !inlinedAuthError">
                <div class="loader-container">
                    <v-progress-linear
                        :indeterminate="true"
                    ></v-progress-linear>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import configType from '@/store/type/configType'
import LoginForm from '@/components/pages/login/LoginForm'
import authType from '@/store/type/authType'

export default {
    components: { LoginForm },
    data: () => ({
        inlinedAuthError: false,
        routeType,
    }),
    computed: {
        ...mapGetters({
            isInlined: configType.getters.INLINED,
            isLoggedIn: authType.getters.IS_LOGGED_IN,
        }),
    },
    mounted() {
        if (this.isLoggedIn) {
            this.$router.push({ name: routeType.ORDER_LIST })

            return
        }

        setTimeout(() => {
            this.inlinedAuthError = true
        }, 4000)
    },
}
</script>

<style>
/* style in pages/_auth */
</style>
