<template>
    <v-form @submit.prevent="login">
        <v-text-field
            v-model="email"
            v-validate="'required|email'"
            class="email"
            name="email"
            :label="$t('auth.email')"
            type="email"
            required
            :disabled="disabled"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
            data-vv-validate-on="blur"
            autofocus
        ></v-text-field>

        <v-text-field
            ref="passwordField"
            v-model="password"
            v-validate="'required'"
            class="password"
            name="password"
            :label="$t('auth.password')"
            required
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            :disabled="disabled"
            data-vv-name="password"
            :error-messages="errors.collect('password')"
            data-vv-validate-on="blur"
            @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-checkbox
            v-model="keepMeSignedIn"
            value="1"
            :label="$t('auth.keep_signed_in')"
            data-vv-name="keep me signed in"
            type="checkbox"
        ></v-checkbox>

        <div class="py-4">
            <v-btn
                type="submit"
                class="white--text"
                color="primary"
                block
                :disabled="disabled"
            >
                {{
                    $t(
                        !disabled
                            ? 'auth.login_submit'
                            : 'auth.login_submitting'
                    )
                }}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'

export default {
    data() {
        return {
            email: '',
            password: '',
            keepMeSignedIn: false,
            showPassword: false,
            disabled: false,
        }
    },
    methods: {
        login() {
            this.$validator.validateAll().then((isValid) => {
                if (!isValid) return

                this.disabled = true

                this.$store
                    .dispatch(authType.actions.LOGIN_WITH_PASSWORD, {
                        email: this.email,
                        password: this.password,
                        keepMeSignedIn: this.keepMeSignedIn === '1',
                    })
                    .then(() => {
                        if (
                            this.$route.query.redirectTo &&
                            this.$route.query.redirectTo !== '/'
                        ) {
                            this.$router.push({
                                path: this.$route.query.redirectTo,
                            })
                        } else {
                            if (
                                this.$store.getters[
                                    authType.getters.IS_RESTAURANT_ADMIN
                                ]
                            )
                                this.$router.push({
                                    name: routeType.ORDER_ACTIVE,
                                })
                            else
                                this.$router.push({
                                    name: routeType.ORDER_LIST,
                                })
                        }
                    })
                    .catch(() => {
                        this.$validator.errors.add([
                            {
                                field: 'email',
                                msg: this.$t('auth.validation.credentials'),
                            },
                        ])
                    })
                    .finally(() => {
                        this.password = ''
                        this.disabled = false
                        this.$nextTick(() => {
                            if (this.$refs.passwordField) {
                                this.$refs.passwordField.focus()
                            }
                        })
                    })
                this.disabled = true
            })

            return false
        },
    },
}
</script>
