<template>
    <v-form @submit.prevent="passwordReset">
        <v-text-field
            ref="passwordField"
            v-model="password"
            v-validate="'required|min:6'"
            class="password"
            name="password"
            :label="$t('auth.new_password')"
            required
            :outline="true"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            :disabled="disabled"
            data-vv-name="password"
            :error-messages="errors.collect('password')"
            data-vv-validate-on="blur"
            @click:append="showPassword = !showPassword"
        ></v-text-field>

        <div class="py-4">
            <v-btn
                type="submit"
                class="white--text"
                color="primary"
                block
                :disabled="disabled"
            >
                {{
                    $t(
                        !disabled
                            ? 'auth.resetpw_submit'
                            : 'auth.reset_req_submitting'
                    )
                }}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import AuthApiClient from '@/api/AuthApiClient'

export default {
    props: {
        token: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            password: '',
            showPassword: false,
            disabled: false,
        }
    },
    methods: {
        passwordReset() {
            this.$validator.validateAll().then((isValid) => {
                if (!isValid) return

                this.disabled = true

                AuthApiClient.passwordReset(this.token, this.password)
                    .then(() => {
                        this.$emit('passwordResetSuccess')
                    })
                    .catch((err) => {
                        if (
                            err.response.data.errors &&
                            err.response.data.errors.password
                        ) {
                            this.$validator.errors.add([
                                {
                                    field: 'password',
                                    msg: err.response.data.errors.password[0],
                                },
                            ])
                        } else {
                            this.$emit('passwordResetFailed')
                        }
                    })
                    .finally(() => {
                        this.password = ''
                        this.disabled = false
                    })
            })
        },
    },
}
</script>
