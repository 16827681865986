<script>
import PageTitle from '@/components/layout/components/pageTitle'
import Back from '@/components/elements/Back'
export default {
    components: { PageTitle, Back },
}
</script>

<template lang="pug">
    .container.fill-screen.d-flex.align-center.justify-center.flex-column
        page-title 501
        h1.error--text.text-center {{ $t('texts.page_not_implemented') }}
        back.mx-auto.d-block.mt-4
</template>
